@import url(https://rsms.me/inter/inter.css);
@media (max-width: 576px) {
  .hiddenSmDown {
    display: none !important; } }

@media (max-width: 786px) {
  .hiddenMdDown {
    display: none !important; } }

@media (max-width: 992px) {
  .hiddenLgDown {
    display: none !important; } }

@media (min-width: 1200px) {
  .hiddenXlDown {
    display: none !important; } }

@media (min-width: 576px) {
  .hiddenSmUp {
    display: none !important; } }

@media (min-width: 786px) {
  .hiddenMdUp {
    display: none !important; } }

@media (min-width: 992px) {
  .hiddenLgUp {
    display: none !important; } }

@media (min-width: 1200px) {
  .hiddenXlUp {
    display: none !important; } }

/*
Example of usage:

@include smUp {
  body {
    background: red;
  }
};

Compiles to

@media (min-width: 576px) {
  body {
    background: red;
    }
};
*/
html {
  font-family: "Inter", sans-serif; }

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter", sans-serif; } }

.introTitle, .wogits .lander input {
  font-size: 25px;
  font-size: 2.5rem;
  line-height: 1.2;
  -webkit-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  letter-spacing: 0em;
  word-spacing: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  margin: 0; }
  @media (min-width: 576px) {
    .introTitle, .wogits .lander input {
      font-size: 45px;
      font-size: 4.5rem;
      line-height: 1.3;
      -webkit-font-feature-settings: "kern" 1;
              font-feature-settings: "kern" 1;
      letter-spacing: 0em;
      word-spacing: 0em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      font-family: "Inter", sans-serif; } }

.mainTitle {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.2;
  -webkit-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  letter-spacing: 0em;
  word-spacing: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  margin: 0; }
  @media (min-width: 576px) {
    .mainTitle {
      font-size: 30px;
      font-size: 3rem;
      line-height: 1.9;
      -webkit-font-feature-settings: "kern" 1;
              font-feature-settings: "kern" 1;
      letter-spacing: 0em;
      word-spacing: 0em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      font-family: "Inter", sans-serif; } }

.subTitle, .locationEntry {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.2;
  -webkit-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  letter-spacing: 0em;
  word-spacing: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-family: "Inter", sans-serif; }
  @media (min-width: 576px) {
    .subTitle, .locationEntry {
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1.4;
      -webkit-font-feature-settings: "kern" 1;
              font-feature-settings: "kern" 1;
      letter-spacing: 0em;
      word-spacing: 0em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      font-family: "Inter", sans-serif; } }

p,
li,
a,
td,
th,
button {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  -webkit-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  letter-spacing: 0em;
  word-spacing: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-family: "Inter", sans-serif; }
  @media (min-width: 576px) {
    p,
    li,
    a,
    td,
    th,
    button {
      font-size: 13px;
      font-size: 1.3rem;
      line-height: 1.6;
      -webkit-font-feature-settings: "kern" 1;
              font-feature-settings: "kern" 1;
      letter-spacing: 0em;
      word-spacing: 0em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      font-family: "Inter", sans-serif; } }

.about p,
.about h3 {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1.2;
  -webkit-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  letter-spacing: 0em;
  word-spacing: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-family: "Inter", sans-serif; }

@media (min-width: 576px) {
  div.container-fluid {
    padding: 0;
    background-color: #fafeff; } }

@media (max-width: 576px) {
  .lander {
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px;
    margin-bottom: 15px; } }

@media (max-width: 786px) {
  .lander .landerContent {
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 786px) {
  .lander {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    min-height: 100vh; } }

@media (max-width: 786px) {
  .lander .landerLinks {
    display: flex;
    justify-content: space-evenly; } }

.lander .landerLinks a {
  display: block; }

@media (min-width: 786px) {
  .index {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center; } }

@media (min-width: 576px) {
  .index .intro {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    padding: 20px;
    min-height: 100vh; } }

.wogits {
  min-height: 100vh; }
  @media (min-width: 576px) {
    .wogits {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center; } }
  .wogits .outputContainer {
    background-color: #abfffa;
    text-align: center;
    padding: 20px; }
    @media (max-width: 576px) {
      .wogits .outputContainer {
        margin-left: -15px;
        margin-right: -15px;
        padding: 15px;
        margin-bottom: 15px; } }
    @media (min-width: 576px) {
      .wogits .outputContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 500px;
        min-height: 100vh; } }
  @media (min-width: 576px) {
    .wogits .inputContainer {
      height: 100vh;
      overflow: auto;
      padding: 15px; } }
  .wogits .inputContainer .react-bootstrap-table {
    padding-top: 20px; }

@media (min-width: 786px) {
  .hetsno {
    min-height: 100vh;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center; } }

.hetsno .hetsnoDings {
  background-color: #abfffa;
  padding: 15px; }
  @media (max-width: 576px) {
    .hetsno .hetsnoDings {
      margin-left: -15px;
      margin-right: -15px;
      padding: 15px;
      margin-bottom: 15px;
      text-align: center; } }
  @media (min-width: 576px) {
    .hetsno .hetsnoDings {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      min-width: 500px; } }
  .hetsno .hetsnoDings img {
    max-width: 200px;
    margin-bottom: 20px; }

.hetsno .hetsnoContainer {
  flex: 1 1;
  overflow: auto; }
  @media (min-width: 576px) {
    .hetsno .hetsnoContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh; } }
  .hetsno .hetsnoContainer > div {
    min-width: 70%; }

body {
  font-family: "Inter", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0b1d44; }

p,
li,
a,
td,
th,
button {
  color: #0b1d44;
  font-weight: 400; }

.btn-primary {
  font-family: "Inter", sans-serif;
  border-color: #857200;
  background: #857200;
  color: #0b1d44; }
  .btn-primary:hover {
    background: #b89d00;
    border-color: #b89d00; }
  .btn-primary:active, .btn-primary:focus {
    background: #abfffa !important;
    border-color: #abfffa !important; }

.introTitle {
  font-weight: 300; }

.subTitle {
  font-weight: 700; }

a {
  color: #524600;
  text-decoration: underline; }
  a:hover {
    text-decoration: none;
    color: #857200; }

.locationEntry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: dashed 10px #ebc900; }
  @media (max-width: 576px) {
    .locationEntry {
      flex-wrap: wrap; } }
  @media (max-width: 576px) {
    .locationEntry span:first-child {
      width: 100%; }
      .locationEntry span:first-child a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 5px; } }
  @media (min-width: 576px) {
    .locationEntry span:first-child {
      min-width: 40%; }
      .locationEntry span:first-child span {
        display: block; } }
  .locationEntry a {
    text-decoration: none; }
  .locationEntry:hover {
    background-color: #abfffa;
    cursor: pointer; }
  .locationEntry .locName {
    font-weight: 800; }

.about {
  margin-top: 50px; }

@media (max-width: 786px) {
  .lander {
    border-bottom: dashed 10px #ebc900;
    padding-bottom: 15px; }
    .lander .mainTitle {
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1.5;
      -webkit-font-feature-settings: "kern" 1;
              font-feature-settings: "kern" 1;
      letter-spacing: 0em;
      word-spacing: 0em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      font-family: "Inter", sans-serif; } }

@media (min-width: 786px) {
  .lander {
    border-left: dashed 10px #ebc900; }
    .lander .mainTitle {
      font-size: 20px;
      font-size: 2rem;
      line-height: 1.5;
      -webkit-font-feature-settings: "kern" 1;
              font-feature-settings: "kern" 1;
      letter-spacing: 0em;
      word-spacing: 0em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: normal;
      font-family: "Inter", sans-serif; } }

.lander img {
  max-width: 150px;
  min-width: 50px;
  margin: 0; }

.wogits .lander input {
  background: transparent;
  border: none; }

.wogits .inputContainer table td, .wogits .inputContainer table th {
  border-color: #ebc900; }

.wogits .inputContainer .pagination .page-item.active .page-link {
  background: #857200;
  border-color: #857200;
  color: #0b1d44; }

.wogits .inputContainer .pagination .page-item .page-link {
  color: #524600; }

@media (max-width: 576px) {
  img {
    max-width: 150px;
    margin: auto; } }

hr {
  border-top: dashed 10px #ebc900; }

