body {
  font-family: $mainFont;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $txtColor;
}
p,
li,
a,
td,
th,
button {
  @extend %fontM;
  color: $txtColor;
  font-weight: 400;
}
.btn-primary {
  font-family: $mainFont;
  border-color: $linkBgColor;
  background: $linkBgColor;
  color: $txtColor;
  &:hover {
    //color: $txtColor;
    background: lighten($linkBgColor, 10%);
    border-color: lighten($linkBgColor, 10%);
  }
  &:active,
  &:focus {
    background: $mainColor !important;
    border-color: $mainColor !important;
  }
}
.introTitle {
  @extend %fontXXL;
  font-weight: 300;
}
.mainTitle {
  @extend %fontXL;
}
.subTitle {
  @extend %fontL;
  font-weight: 700;
}
a {
  color: $linkColor;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: lighten($linkColor, 10%);
  }
}
.locationEntry {
  @extend %fontL;
  @include flexBox(null, space-between, center);
  padding-bottom: 10px;
  @include smDown {
    flex-wrap: wrap;
  }
  span {
    
    &:first-child {
      @include smDown {
        width: 100%;
        a {
          @include flexBox(null, flex-start, center);
          padding-bottom: 5px;
        }
      }
      @include smUp {
        min-width: 40%;
        span {
          display: block;
        }
      }
    }
  }
  border-bottom: $veloLine;
  a {
    text-decoration: none;
  }
  &:hover {
    background-color: $mainColor;
    cursor: pointer;
  }
  .locName {
    font-weight: 800;
  }
}
.about {
  margin-top: 50px;
  p,
  h3 {
    @extend %fontS;
  }
}
