@include smUp {
  div.container-fluid {
    padding: 0;
    background-color: $bgColor;
  }
}
.lander {
  @include smDown {
    margin-left: -$defaultPadding;
    margin-right: -$defaultPadding;
    padding: $defaultPadding;
    margin-bottom: $defaultPadding;
  }
  .landerContent {
    @include mdDown {
      @include flexBox(null, center, center);
    }
  }
  @include mdUp {
    @include flexBox(column, center, null);
    padding: 0 $defaultPadding * 2;
    min-height: 100vh;
  }
  .landerLinks {
    @include mdDown {
      @include flexBox(null, space-evenly, null);
    }
    a {
      display: block;
    }
  }
}

//start page

.index {
  @include mdUp {
    @include flexBox(row-reverse, center, center);
  }
  .intro {
    @include smUp {
      @include flexBox(null, center, center);
      flex: 1;
      padding: 20px;
      min-height: 100vh;
    }
  }
}

//table page

.wogits {
  min-height: 100vh;
  @include smUp {
    @include flexBox(row-reverse, center, center);
  }
  .outputContainer {
    background-color: $mainColor;
    text-align: center;
    @include smDown {
      margin-left: -$defaultPadding;
      margin-right: -$defaultPadding;
      padding: $defaultPadding;
      margin-bottom: $defaultPadding;
    }
    padding: 20px;
    @include smUp {
      @include flexBox(column, center, center);
      min-width: $sidebarWidth;
      min-height: 100vh;
    }
  }
  .inputContainer {
    @include smUp {
      height: 100vh;
      overflow: auto;
      padding: $defaultPadding;
    }
    .react-bootstrap-table {
      padding-top: 20px;
    }
  }
}

//hewtsno page

.hetsno {
  @include mdUp {
    min-height: 100vh;
    @include flexBox(row-reverse, center, center);
  }
  .hetsnoDings {
    @include smDown {
      margin-left: -$defaultPadding;
      margin-right: -$defaultPadding;
      padding: $defaultPadding;
      margin-bottom: $defaultPadding;
      text-align: center;
    }
    @include smUp {
      @include flexBox(column, center, center);
      min-height: 100vh;
      min-width: $sidebarWidth;
    }
    background-color: $mainColor;
    padding: $defaultPadding;
    img {
      max-width: 200px;
      margin-bottom: 20px;
    }
  }
  .hetsnoContainer {
    @include smUp {
      @include flexBox(null, center, center);
      height: 100vh;
    }
    flex: 1;
    overflow: auto;
    > div {
      min-width: 70%;
    }
  }
}
