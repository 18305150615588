.lander {
    @include mdDown {
        border-bottom: $veloLine;
        padding-bottom: $defaultPadding;
        .mainTitle {
            @include fontSize(1.6, 1.5);
        }
    }
    @include mdUp {
        border-left: $veloLine;
        .mainTitle {
            @include fontSize(2, 1.5);
        }
    }
    img {
        max-width: 150px;
        min-width: 50px;
        margin: 0;
    }
}

//stations tabelle
.wogits {
    .lander {
        input {
            @extend %fontXXL;
            background: transparent;
            border: none;
        }
    }
    .inputContainer {
        table {
            td, th {
                border-color: $veloWeg;
            }
        }
        .pagination {
            .page-item {
                &.active {
                    .page-link {
                        background: $linkBgColor;
                        border-color: $linkBgColor;
                        color: $txtColor;
                    }
                }
                .page-link {
                    color: $linkColor;
                }
            }
        }
    }
}



// das funktioniert alles noch nicht.
@include smDown {
    img {
        max-width: 150px;
        margin: auto;
    }
}

hr {
    border-top: $veloLine;
}